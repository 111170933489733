import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout } from '../components/Layout';
import { useNavigate } from 'react-router-dom';
import { GoBackModal } from '../components/GoBackModal';
import { UserInfo } from '../stores';
import { useRecoilState } from 'recoil';

export const Wrap = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);
  const [showGoto, setshowGoto] = useState(false);
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  return (
    <Layout>
      <div className="d-flex">
        <div className="left_side">
          <ul className="menu">
            
            {(userinfo?.email == "wang1@arsome.com" || userinfo?.email == "oyanadel@arsome.com") && (
              <>
                <li
                  className={`cursor ${
                    location.pathname.indexOf('overview') > 0 ? 'selected' : ''
                  }`}
                  onClick={() => {
                    navigate('overview');
                  }}
                >
                  Overview
                </li>
                <li
                  className={`cursor ${
                    location.pathname.indexOf('admins') > 0 ? 'selected' : ''
                  }`}
                  onClick={() => {
                    navigate('admins');
                  }}
                >
                  Administrators
                </li>
              </>
            )}
            <li
              className={`cursor ${
                location.pathname.indexOf('users') > 0 ? 'selected' : ''
              }`}
              onClick={() => {
                navigate('users');
              }}
            >
              Users
            </li>
            
            {/* <li
              className={`cursor ${
                location.pathname.indexOf('state_grant') > 0 ? 'selected' : ''
              }`}
              onClick={() => {
                navigate('state_grant');
              }}
            >
              Stategrants
            </li> */}
            {/* <li
              className={`cursor ${
                location.pathname.indexOf('school_list') > 0 ? 'selected' : ''
              }`}
              onClick={() => {
                navigate('school_list');
              }}
            >
              schools
            </li> */}
            <li
              className={`cursor ${
                location.pathname.indexOf('reports') > 0 ? 'selected' : ''
              }`}
              onClick={() => {
                navigate('reports');
              }}
            >
              Reports
            </li>

            {/* <li
              className={`cursor ${
                location.pathname.indexOf('logs') > 0 ? 'selected' : ''
              }`}
              onClick={() => {
                navigate('logs');
              }}
            >
              logs
            </li>

            <li
              className={`cursor ${
                location.pathname.indexOf('search') > 0 ? 'selected' : ''
              }`}
              onClick={() => navigate('search')}
            >
              search algorithim
            </li>
            <li className='cursor' onClick={() => setshowGoto(true)}>
              help
            </li>
            {userinfo?.admin_Email === 'jinsp317@gmail.com' && (
              <li className='cursor' onClick={() => navigate('activities')}>
                activities
              </li>
            )} */}
          </ul>
        </div>
        <div className="flex-fill pt-3 px-3">
          <Outlet />
        </div>
      </div>
      <GoBackModal
        show={showGoto}
        onOk={() => {
          setshowGoto(false);
          navigate('users');
        }}
      />
    </Layout>
  );
};
