import React from 'react';
import { Navbar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import log from '../assets/images/edusfere_logo.png';
import { UserInfo } from '../stores';
interface Props {
  isLogoutable: boolean;
}
export const TopHeader = (props: Props) => {
  const navigate = useNavigate();
  const [userinfo, setUserinfo] = useRecoilState(UserInfo);
  return (
    <Navbar bg="light" expand="sm" fixed="top" className="box_shadow">
      <div className="d-flex w-100 justify-content-between">
        <div className={' d-flex ps-3'}>
          <div>
            <svg
              width="49"
              height="29"
              viewBox="0 0 49 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.5 0V28.9614L33.4378 27.3055L38.8797 26.2935V17.209L42.8233 15.1965L44.4077 14.3858L48.3341 12.3849L49 12.0457L47.4558 11.2867L24.5 0Z"
                fill="#191A32"
              />
              <path
                d="M0 12.0457L0.648678 12.3735L10.1203 17.209V26.2935L24.5 28.9614V0L1.91732 11.1028L0 12.0457Z"
                fill="#282D41"
              />
              <path
                d="M44.1807 13.9403L47.8828 12.0537L47.2353 11.7354L47.2352 11.7354L25 0.803002V28.3602L33.3464 26.8139L33.3467 26.8139L38.3797 25.8779V17.209V16.9028L38.6524 16.7636L42.5956 14.7514L42.596 14.7511L44.1799 13.9407L44.1807 13.9403Z"
                fill="#3FC6AD"
                stroke="white"
              />
              <path
                d="M0 12.0457L0.648678 12.3735L10.1203 17.209V26.2935L24.5 28.9614V0L1.91732 11.1028L0 12.0457Z"
                fill="#2B574F"
              />
              <path
                d="M22.5668 5.97376C22.5668 6.65982 23.5047 7.21685 24.6612 7.21685C25.8177 7.21685 26.7557 6.65991 26.7557 5.97376C26.7557 5.28769 25.8176 4.73257 24.6612 4.73257C23.5048 4.73257 22.5668 5.28769 22.5668 5.97376Z"
                fill="#3FC6AD"
              />
            </svg>
          </div>
          <div className="d-flex align-items-end">
            <h5 className="header_logo mb-0 fst-italic ">MyCollegeCosts</h5>
          </div>
        </div>

        {props.isLogoutable && (
          <div className="common_btns">
            <button
              className="border-0 bg-transparent me-3"
              onClick={() => {
                setUserinfo(undefined);
                navigate('/login');
              }}
            >
              logout
            </button>
          </div>
        )}
      </div>
    </Navbar>
  );
};
