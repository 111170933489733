import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { Member } from '../models';
import { Admin } from '../models/admin';
const { persistAtom } = recoilPersist();
export const UserInfo = atom<Admin | undefined>({
  key: 'UserInfo',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const token = atom<string | undefined>({
  key: 'token',
  default: undefined,
});
