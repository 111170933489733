import { useState } from 'react';
import {
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import ReactLoading from 'react-loading';
import {
  UseSaveStateGrant,
  useStateGrants,
} from '../services/Query';
import { useForm } from 'react-hook-form';
type grantFrm = {
  id: string;
  nameofState: string;
  codeOfState: string | null;
  secondaryStateCode: string;
  stateGrantAmount: number;
  description: string | null;
};
export const StateGrant = () => {
  const [editMode, setEditMode] = useState(false);
  const stateList = [
    {
      name: 'Alabama',
      abbreviation: 'AL',
    },
    {
      name: 'Alaska',
      abbreviation: 'AK',
    },
    {
      name: 'American Samoa',
      abbreviation: 'AS',
    },
    {
      name: 'Arizona',
      abbreviation: 'AZ',
    },
    {
      name: 'Arkansas',
      abbreviation: 'AR',
    },
    {
      name: 'California',
      abbreviation: 'CA',
    },
    {
      name: 'Colorado',
      abbreviation: 'CO',
    },
    {
      name: 'Connecticut',
      abbreviation: 'CT',
    },
    {
      name: 'Delaware',
      abbreviation: 'DE',
    },
    {
      name: 'District Of Columbia',
      abbreviation: 'DC',
    },
    {
      name: 'Federated States Of Micronesia',
      abbreviation: 'FM',
    },
    {
      name: 'Florida',
      abbreviation: 'FL',
    },
    {
      name: 'Georgia',
      abbreviation: 'GA',
    },
    {
      name: 'Guam',
      abbreviation: 'GU',
    },
    {
      name: 'Hawaii',
      abbreviation: 'HI',
    },
    {
      name: 'Idaho',
      abbreviation: 'ID',
    },
    {
      name: 'Illinois',
      abbreviation: 'IL',
    },
    {
      name: 'Indiana',
      abbreviation: 'IN',
    },
    {
      name: 'Iowa',
      abbreviation: 'IA',
    },
    {
      name: 'Kansas',
      abbreviation: 'KS',
    },
    {
      name: 'Kentucky',
      abbreviation: 'KY',
    },
    {
      name: 'Louisiana',
      abbreviation: 'LA',
    },
    {
      name: 'Maine',
      abbreviation: 'ME',
    },
    {
      name: 'Marshall Islands',
      abbreviation: 'MH',
    },
    {
      name: 'Maryland',
      abbreviation: 'MD',
    },
    {
      name: 'Massachusetts',
      abbreviation: 'MA',
    },
    {
      name: 'Michigan',
      abbreviation: 'MI',
    },
    {
      name: 'Minnesota',
      abbreviation: 'MN',
    },
    {
      name: 'Mississippi',
      abbreviation: 'MS',
    },
    {
      name: 'Missouri',
      abbreviation: 'MO',
    },
    {
      name: 'Montana',
      abbreviation: 'MT',
    },
    {
      name: 'Nebraska',
      abbreviation: 'NE',
    },
    {
      name: 'Nevada',
      abbreviation: 'NV',
    },
    {
      name: 'New Hampshire',
      abbreviation: 'NH',
    },
    {
      name: 'New Jersey',
      abbreviation: 'NJ',
    },
    {
      name: 'New Mexico',
      abbreviation: 'NM',
    },
    {
      name: 'New York',
      abbreviation: 'NY',
    },
    {
      name: 'North Carolina',
      abbreviation: 'NC',
    },
    {
      name: 'North Dakota',
      abbreviation: 'ND',
    },
    {
      name: 'Northern Mariana Islands',
      abbreviation: 'MP',
    },
    {
      name: 'Ohio',
      abbreviation: 'OH',
    },
    {
      name: 'Oklahoma',
      abbreviation: 'OK',
    },
    {
      name: 'Oregon',
      abbreviation: 'OR',
    },
    {
      name: 'Palau',
      abbreviation: 'PW',
    },
    {
      name: 'Pennsylvania',
      abbreviation: 'PA',
    },
    {
      name: 'Puerto Rico',
      abbreviation: 'PR',
    },
    {
      name: 'Rhode Island',
      abbreviation: 'RI',
    },
    {
      name: 'South Carolina',
      abbreviation: 'SC',
    },
    {
      name: 'South Dakota',
      abbreviation: 'SD',
    },
    {
      name: 'Tennessee',
      abbreviation: 'TN',
    },
    {
      name: 'Texas',
      abbreviation: 'TX',
    },
    {
      name: 'Utah',
      abbreviation: 'UT',
    },
    {
      name: 'Vermont',
      abbreviation: 'VT',
    },
    {
      name: 'Virgin Islands',
      abbreviation: 'VI',
    },
    {
      name: 'Virginia',
      abbreviation: 'VA',
    },
    {
      name: 'Washington',
      abbreviation: 'WA',
    },
    {
      name: 'West Virginia',
      abbreviation: 'WV',
    },
    {
      name: 'Wisconsin',
      abbreviation: 'WI',
    },
    {
      name: 'Wyoming',
      abbreviation: 'WY',
    },
  ];
  const {
    isFetching: logLoading,
    data: state_grants,
    refetch: getGrantList,
  } = useStateGrants();
  const {
    register,
    reset,
    getValues,
    formState: { isValid },
  } = useForm<grantFrm>({ mode: 'onChange' });
  const { mutate: saveGrant, isLoading } = UseSaveStateGrant();
  const onAddGrant = () => {
    reset({
      nameofState: '',
      codeOfState: '',
      secondaryStateCode: '',
      stateGrantAmount: 0,
      description: '',
    });
    setEditMode(true);
  };
  const onSaveGrant = () => {
    const data = getValues();
    saveGrant(JSON.stringify(data), {
      onSuccess: () => {
        getGrantList();
        setEditMode(false);
      },
    });
  };
  if (logLoading) {
    return (
      <div className="w-100 text-center py-4 ">
        <ReactLoading
          type="spinningBubbles"
          className="mx-auto my-auto"
          height={'80px'}
          width={'80px'}
          color="#666666"
        />
      </div>
    );
  }
  if (editMode) {
    return (
      <div>
        <Card className="w-50">
          <Card.Header>State Grants</Card.Header>
          <Card.Body>
            <div className="d-grid gap-3">
              <InputGroup>
                <InputGroup.Text>Name of State</InputGroup.Text>
                <Form.Select {...register('nameofState', { required: true })}>
                  <option value=""></option>
                  {stateList.map((it) => (
                    <option value={it.name} key={it.abbreviation}>
                      {it.name}
                    </option>
                  ))}
                </Form.Select>
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>code Of State</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('codeOfState', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Secondary StateCode</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('secondaryStateCode', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>State GrantAmount</InputGroup.Text>
                <Form.Control
                  type="number"
                  {...register('stateGrantAmount', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>description</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('description', {
                    required: true,
                  })}
                />
              </InputGroup>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <button
              className="bg-white rounded-2 px-3"
              disabled={!isValid}
              onClick={onSaveGrant}
            >
              {isLoading && (
                <Spinner
                  animation="border"
                  variant="secondary"
                  size="sm"
                  className="me-1"
                />
              )}
              Save
            </button>
            <button
              className="bg-white rounded-2 px-3 ms-2"
              onClick={() => {
                setEditMode(false);
              }}
            >
              close
            </button>
          </Card.Footer>
        </Card>
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex justify-content-end mb-1">
        <button
          className="btn btn-outline-secondary fw-bold"
          onClick={() => {
            onAddGrant();
          }}
        >
          add grant
        </button>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            {/* <th></th> */}
            <th>NameofState</th>
            <th>CodeofState</th>
            <th>SecondaryStateCode</th>
            <th>StateGrantAmount</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {state_grants &&
            state_grants.map((it, idx) => (
              <tr key={idx}>
                <td>{it.nameofState}</td>
                <td>{it.codeOfState}</td>
                <td>{it.secondaryStateCode}</td>
                <td>{it.stateGrantAmount}</td>
                <td>{it.description}</td>
                <td>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      reset({
                        id: it.id,
                        nameofState: it.nameofState,
                        codeOfState: it.codeOfState,
                        secondaryStateCode: it.secondaryStateCode,
                        description: it.description,
                        stateGrantAmount: it.stateGrantAmount,
                      });
                      setEditMode(true);
                    }}
                  >
                    edit
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
