import React, { useEffect, useState } from 'react';
import { Container, Form, Modal, Spinner } from 'react-bootstrap';
import { UseGetDecodedSSN } from '../services/Query';
import checked from '../assets/images/checked.svg';
interface Props {
  show: boolean;
  onOk: () => void;
  onDiscard: () => void;
}

export const SSNModal = (props: Props) => {
  const [ssnNumber, setSsnNumber] = useState('');
  const { mutate: getDecodedValue, isLoading } = UseGetDecodedSSN();
  const [isAction, setIsAction] = useState(false);
  const [decodeVal, setDecodeVal] = useState('');
  const format = (v: string) => {
    const deVal = v.toString();
    if (deVal) {
      if (deVal.length <= 3) {
        return v;
      }
      if (deVal.length > 3 && deVal.length <= 5) {
        return `${deVal.slice(0, 3)}-${deVal.slice(3)}`;
      }
      if (deVal.length > 5) {
        return `${deVal.slice(0, 3)}-${deVal.slice(3, 5)}-${deVal.slice(5)}`;
      }
    } else {
      return '';
    }
  };
  useEffect(() => {
    setDecodeVal('');
    setIsAction(false);
  }, [ssnNumber]);
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className="dashboard_video"
      centered={true}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Get Number</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <div className="my-3">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Input String</Form.Label>
              <Form.Control
                type="text"
                placeholder="Input String"
                pattern="[A-Za-z]*"
                value={ssnNumber}
                onChange={(e) => {
                  const val = e.target.value;
                  setSsnNumber(val.replace(/[^A-Za-z]/g, ''));
                }}
              />
            </Form.Group>
          </div>
          <div className="my-3">
            <Form.Group className="mb-3">
              <Form.Label>View Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="View Number"
                readOnly
                value={format(decodeVal)}
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-center pt-3">
            <button
              disabled={ssnNumber == '' || ssnNumber.length != 11}
              className=" border_warn bg-white rounded_3 py-2 px-4 fs-5 fw-bold"
              onClick={() => {
                getDecodedValue(ssnNumber, {
                  onSuccess: (res) => {
                    if (res != '') setDecodeVal(res);
                    else setDecodeVal('');
                  },
                });
              }}
            >
              {isLoading && <Spinner animation="border" className="me-1" />}
              View
            </button>
            <button
              disabled={!decodeVal}
              className=" border_warn bg-white rounded_3 py-2 px-4 fs-5 ms-3 fw-bold"
              onClick={async () => {
                try {
                  const val = format(decodeVal);
                  if (val) {
                    // setIsAction(true);
                    await navigator.clipboard.writeText(val);
                    setIsAction(true);
                  }
                } catch (error) {}
              }}
            >
              {isAction && (
                <img src={checked} alt="" width={16} className="me-1" />
              )}
              {isAction ? 'Copied' : 'Copy'}
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
